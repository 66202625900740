import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import paths from 'paths';
import Loadable from 'shared/components/Loadable';
import LayoutHeaderFooter from 'layouts/LayoutHeaderFooter';
import withTitle from 'shared/HOCs/withTitle';
import AuthGuard from 'shared/components/AuthGuard';

const AllFavoritesPage = withTitle('Favorites')(Loadable(lazy(() => import('apps/favoritePreceptors/pages/AllFavorites'))));

// -----------------------|| FAVORITES ROUTING ||-----------------------//

const Routes = () => {
  const location = useLocation();

  return (
    <LayoutHeaderFooter>
      <Switch location={location} key={location.pathname}>
        <AuthGuard>
          <Route
            path={[
              paths.favoritesPaths.allFavoritePreceptors.pattern,
            ]}
            exact
            component={AllFavoritesPage}
          />
        </AuthGuard>
      </Switch>
    </LayoutHeaderFooter>
  );
};

export default Routes;
