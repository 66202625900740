import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import paths from 'paths';
import Loadable from 'shared/components/Loadable';
import LayoutHeaderFooter from 'layouts/LayoutHeaderFooter';
import withTitle from 'shared/HOCs/withTitle';
import AuthGuard from 'shared/components/AuthGuard';

const WelcomePage = withTitle('Welcome')(Loadable(lazy(() => import('apps/wizard/pages/Welcome'))));
const WizardStartPage = withTitle('Complete Profile')(Loadable(lazy(() => import('apps/wizard/pages/WizardStart'))));
const WizardCompletedPage = withTitle('Profile Completed')(Loadable(lazy(() => import('apps/wizard/pages/WizardCompleted'))));

// -----------------------|| WIZARD ROUTING ||-----------------------//

const Routes = () => {
  const location = useLocation();

  return (
    <LayoutHeaderFooter>
      <Switch location={location} key={location.pathname}>
        <AuthGuard>
          <Route
            path={paths.wizardPaths.welcome.pattern}
            exact
            component={WelcomePage}
          />
          <Route
            path={paths.wizardPaths.wizardStart.pattern}
            exact
            component={WizardStartPage}
          />
          <Route
            path={paths.wizardPaths.wizardCompleted.pattern}
            exact
            component={WizardCompletedPage}
          />
        </AuthGuard>
      </Switch>
    </LayoutHeaderFooter>
  );
};

export default Routes;
