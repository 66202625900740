import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import paths from 'paths';
import Loadable from 'shared/components/Loadable';
import LayoutHeaderFooter from 'layouts/LayoutHeaderFooter';
import withTitle from 'shared/HOCs/withTitle';
import AuthGuard from 'shared/components/AuthGuard';

const RotationReservationPaymentPage = withTitle('Reservation Payment')(Loadable(lazy(() => import('apps/rotation/pages/RotationReservationPayment'))));
const RotationSearchPage = withTitle('Search')(Loadable(lazy(() => import('apps/rotation/pages/RotationSearch'))));
const RotationInProcessPage = withTitle('Search')(Loadable(lazy(() => import('apps/rotation/pages/RotationInProcess'))));
const RotationPreferencesPage = withTitle('Search')(Loadable(lazy(() => import('apps/rotation/pages/RotationPreferences'))));
const MyRotationsTabsPage = withTitle('Search')(Loadable(lazy(() => import('apps/rotation/pages/MyRotationsTabs'))));

const RotationStartActionPage = withTitle('Start Rotation')(Loadable(lazy(() => import('apps/rotation/pages/RotationStartActionPage'))));
const AcceptStudentActionPage = withTitle('Accept Request')(Loadable(lazy(() => import('apps/rotation/pages/AcceptStudentActionPage'))));
const RejectStudentActionPage = withTitle('Decline Request')(Loadable(lazy(() => import('apps/rotation/pages/RejectStudentActionPage'))));

// -----------------------|| COMMON ROUTING ||-----------------------//

const Routes = () => {
  const location = useLocation();

  return (
    <LayoutHeaderFooter>
      <Switch location={location} key={location.pathname}>
        <Route
          path={paths.rotationPaths.confirmRotationStart.pattern}
          exact
          component={RotationStartActionPage}
        />
        <Route
          path={paths.rotationPaths.acceptStudent.pattern}
          exact
          component={AcceptStudentActionPage}
        />
        <Route
          path={paths.rotationPaths.rejectStudent.pattern}
          exact
          component={RejectStudentActionPage}
        />
        <AuthGuard>
          <Route
            path={paths.rotationPaths.rotationReservationPayment.pattern}
            exact
            component={RotationReservationPaymentPage}
          />
          <Route
            path={[
              paths.rotationPaths.rotationAllPreceptors.pattern,
              paths.rotationPaths.rotationFavorites.pattern,
              paths.rotationPaths.rotationCanceled.pattern,
            ]}
            exact
            component={RotationSearchPage}
          />
          <Route
            path={[
              paths.rotationPaths.rotationPreceptor.pattern,
              paths.rotationPaths.rotationPaperwork.pattern,
              paths.rotationPaths.rotationPayments.pattern,
            ]}
            exact
            component={RotationInProcessPage}
          />
          <Route
            path={paths.rotationPaths.rotationPreferences.pattern}
            exact
            component={RotationPreferencesPage}
          />
          <Route
            path={paths.rotationPaths.tabName.pattern}
            exact
            component={MyRotationsTabsPage}
          />
        </AuthGuard>
      </Switch>
    </LayoutHeaderFooter>
  );
};

export default Routes;
