import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
setTimeout(() => {
  const app = document.getElementById('root');
  if (!app || app.innerHTML.trim() === '') {
    window.location.reload();
  }
}, 3000);
