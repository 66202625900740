import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import paths from 'paths';
import Loadable from 'shared/components/Loadable';
import withTitle from 'shared/HOCs/withTitle';
import SearchPublicLayout from 'layouts/SearchPublicLayout';

const SearchPublicPage = withTitle('Search Preceptor')(Loadable(lazy(() => import('apps/searchPublic/pages/SearchPublic'))));
const PreceptorProfilePage = Loadable(lazy(() => import('apps/searchPublic/pages/PreceptorProfile')));

// -----------------------|| SEARCH PUBLIC ROUTING ||-----------------------//

const Routes = () => {
  const location = useLocation();

  return (
    <SearchPublicLayout>
      <Switch location={location} key={location.pathname}>
        <Route
          path={[
            paths.searchPublicPaths.publicSearchPreceptor.pattern,
            paths.searchPublicPaths.publicSearch.pattern,
          ]}
          exact
          component={SearchPublicPage}
        />
        <Route
          path={paths.searchPublicPaths.preceptorProfile.pattern}
          exact
          component={PreceptorProfilePage}
        />
      </Switch>
    </SearchPublicLayout>
  );
};

export default Routes;
