import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import paths from 'paths';
import Loadable from 'shared/components/Loadable';
import LayoutHeaderFooter from 'layouts/LayoutHeaderFooter';
import withTitle from 'shared/HOCs/withTitle';
import AuthGuard from 'shared/components/AuthGuard';

const StudentProfilePage = withTitle('Student Profile')(Loadable(lazy(() => import('apps/studentProfile/pages/StudentProfile'))));

// -----------------------|| COMMON ROUTING ||-----------------------//

const Routes = () => {
  const location = useLocation();

  return (
    <LayoutHeaderFooter>
      <Switch location={location} key={location.pathname}>
        <AuthGuard>
          <Route
            path={paths.studentProfilePaths.studentProfile.pattern}
            exact
            component={StudentProfilePage}
          />
        </AuthGuard>
      </Switch>
    </LayoutHeaderFooter>
  );
};

export default Routes;
