import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import paths from 'paths';
import Loadable from 'shared/components/Loadable';
import LayoutHeaderFooter from 'layouts/LayoutHeaderFooter';
import withTitle from 'shared/HOCs/withTitle';
import AuthGuard from 'shared/components/AuthGuard';

const FinancesPage = withTitle('Finances')(Loadable(lazy(() => import('apps/finances/pages/Finances'))));

// -----------------------|| FINANCES ROUTING ||-----------------------//

const Routes = () => {
  const location = useLocation();

  return (
    <LayoutHeaderFooter>
      <Switch location={location} key={location.pathname}>
        <AuthGuard>
          <Route
            path={[
              paths.financesPaths.history.pattern,
              paths.financesPaths.myCards.pattern,
            ]}
            exact
            component={FinancesPage}
          />
        </AuthGuard>
      </Switch>
    </LayoutHeaderFooter>
  );
};

export default Routes;
